import React from 'react';
import { Redirect } from 'react-router-dom';
import { Route } from 'react-router-dom';
import Voucher from '../pages/Voucher';
import VoucherCreateAndDetails from '../pages/Voucher/voucherCreateAndDetails';
import Marketplaces from '../pages/Marketplaces';
import PrivateRoute from './PrivateRoute';
import { exact } from 'prop-types';
// auth
const Logout = React.lazy(() => import('../pages/account/Logout'));

const ErrorPageNotFound = React.lazy(() =>
  import('../pages/error/PageNotFound')
);
const send = React.lazy(() => import('../pages/panelOperation'));
const SendDetails = React.lazy(() =>
  import('../pages/panelOperation/Details/index')
);
const DropsPoint = React.lazy(() => import('../pages/dropsPoint'));
const DropsPointCreate = React.lazy(() =>
  import('../pages/dropsPoint/importPoint/dropsPointCreate')
);
const ImportEditPoint = React.lazy(() =>
  import('../pages/dropsPoint/importPoint/importEditPoint')
);
const DropsPointDetails = React.lazy(() =>
  import('../pages/dropsPoint/detailsEditPoint')
);
const DropsPointDetailsFees = React.lazy(() =>
  import('../pages/dropsPoint/components/pudoValue')
);
const MarketplaceFeeCreateAndDetails = React.lazy(() =>
  import(
    '../pages/dropsPoint/components/pudoValue/createAndDetailsMarketplaceFee'
  )
);
const ServerError = React.lazy(() => import('../pages/error/ServerError'));
const LabelPage = React.lazy(() =>
  import('../pages/panelOperation/LabelPage/LabelPage')
);
const TokenShopee = React.lazy(() => import('../pages/tokenShopee'));
const ReportShopee = React.lazy(() => import('../pages/reportShopee'));
const Webhook = React.lazy(() => import('../pages/Marketplaces/webhook'));
const CreateAndDetails = React.lazy(() =>
  import('../pages/Marketplaces/createAndDetails')
);
const Operators = React.lazy(() => import('../pages/dropsPoint/operators'));
const OperatorCreate = React.lazy(() =>
  import('../pages/dropsPoint/operators/createOperator')
);
const OperatorManagement = React.lazy(() => import('../pages/operators'));
const GenerateLabel = React.lazy(() => import('../pages/generateLabel'));
const GenerateLabeless = React.lazy(() =>
  import('../pages/generateLabel/components/labelessHTML/LabelessPage')
);
const Signatories = React.lazy(() =>
  import('../pages/prospection/signatories')
);
const SignatoriesEdit = React.lazy(() =>
  import('../pages/prospection/signatories/SignatoriesEdit')
);
const Prospect = React.lazy(() => import('../pages/prospect'));

const ProspectDetails = React.lazy(() =>
  import('../pages/prospect/detailsEditProspect')
);

const PreRegistration = React.lazy(() => import('../pages/preRegistration'));
const Models = React.lazy(() => import('../pages/prospection/models/index'));
const CreateModel = React.lazy(() =>
  import('../pages/prospection/models/createModel')
);

const Additives = React.lazy(() => import('../pages/prospection/additives/index'));
const CreateAdditives = React.lazy(() => import('../pages/prospection/additives/createAdditives'));

const SellerOrders = React.lazy(() => import('../pages/sellerOrders'));

const SendContractPage = React.lazy(() => import('../pages/sendContract'));

// root routes
const rootRoute = {
  path: '/',
  exact: true,
  name: 'Home',
  component: () => <Redirect to="/send" />,
  route: PrivateRoute,
};

// dashboards
const dashboardRoutes = {
  path: '/dashboard',
  name: 'Dashboards',
  icon: 'uil-home-alt',
  header: 'Navigation',
  children: [
    {
      path: '/send/details/:id',
      name: 'SendDetails',
      badge: {
        variant: 'success',
        text: '3',
      },
      component: SendDetails,
      route: PrivateRoute,
    },
    {
      path: '/send',
      name: 'send',
      badge: {
        variant: 'success',
        text: '3',
      },
      component: send,
      route: PrivateRoute,
    },
    {
      path: '/drops-point/details/:pudoId/operators/create/:operatorId',
      name: 'operator-create',
      badge: {
        variant: 'success',
        text: '3',
      },
      component: OperatorCreate,
      route: PrivateRoute,
    },
    {
      path: '/drops-point/details/:pudoId/operators',
      name: 'operators',
      badge: {
        variant: 'success',
        text: '3',
      },
      component: Operators,
      route: PrivateRoute,
    },
    {
      path: '/drops-point/details/fees/:id/details/:feeId',
      name: 'marketplaceFeeCreateAndDetails',
      badge: {
        variant: 'success',
        text: '3',
      },
      component: MarketplaceFeeCreateAndDetails,
      route: PrivateRoute,
    },
    {
      path: '/drops-point/details/fees/:id/create',
      name: 'marketplaceFeeCreateAndDetails',
      badge: {
        variant: 'success',
        text: '3',
      },
      component: MarketplaceFeeCreateAndDetails,
      route: PrivateRoute,
    },
    {
      path: '/drops-point/details/fees/:id',
      name: 'dropsPointDetailsFees',
      badge: {
        variant: 'success',
        text: '3',
      },
      component: DropsPointDetailsFees,
      route: PrivateRoute,
    },
    {
      path: '/drops-point/details/:id',
      name: 'dropsPointDetails',
      badge: {
        variant: 'success',
        text: '3',
      },
      component: DropsPointDetails,
      route: PrivateRoute,
    },
    {
      path: '/drops-point',
      name: 'dropsPoint',
      exact: true,
      badge: {
        variant: 'success',
        text: '3',
      },
      component: DropsPoint,
      route: PrivateRoute,
    },
    {
      path: '/drops-point/send-contract',
      name: 'dropsPointContract',
      exact: true,
      badge: {
        variant: 'success',
        text: '3',
      },
      component: SendContractPage,
      route: PrivateRoute,
    },
    {
      path: '/operators',
      name: 'operatorManagement',
      exact: true,
      badge: {
        variant: 'success',
        text: '3',
      },
      component: OperatorManagement,
      route: PrivateRoute,
    },
    {
      path: '/drops-point/create',
      name: 'dropsPointCreate',
      exact: true,
      badge: {
        variant: 'success',
        text: '3',
      },
      component: DropsPointCreate,
      route: PrivateRoute,
    },
    {
      path: '/drops-point/import-edit-point/:id',
      name: 'ImportEditPoint',
      exact: true,
      badge: {
        variant: 'success',
        text: '3',
      },
      component: ImportEditPoint,
      route: PrivateRoute,
    },
    {
      path: '/voucher',
      name: 'voucher',
      exact: true,
      badge: {
        variant: 'success',
        text: '3',
      },
      component: Voucher,
      route: PrivateRoute,
    },
    {
      path: '/voucher/details/:id',
      name: 'voucherDetails',
      exact: true,
      badge: {
        variant: 'success',
        text: '3',
      },
      component: VoucherCreateAndDetails,
      route: PrivateRoute,
    },
    {
      path: '/voucher/create',
      name: 'voucherCreate',
      exact: true,
      badge: {
        variant: 'success',
        text: '3',
      },
      component: VoucherCreateAndDetails,
      route: PrivateRoute,
    },
    {
      path: '/marketplaces',
      name: 'marketplaces',
      exact: true,
      badge: {
        variant: 'success',
        text: '3',
      },
      component: Marketplaces,
      route: PrivateRoute,
    },
    {
      path: '/marketplaces/details/:id',
      name: 'marketplacesDetails',
      exact: true,
      badge: {
        variant: 'success',
        text: '3',
      },
      component: CreateAndDetails,
      route: PrivateRoute,
    },
    {
      path: '/marketplaces/create',
      name: 'marketplacesCreate',
      exact: true,
      badge: {
        variant: 'success',
        text: '3',
      },
      component: CreateAndDetails,
      route: PrivateRoute,
    },
    /* {
      path: '/token-shopee',
      name: 'tokenShopee',
      badge: {
        variant: 'success',
        text: '3',
      },
      component: TokenShopee,
      route: PrivateRoute,
    },
    {
      path: '/report-shopee',
      name: 'reportShopee',
      badge: {
        variant: 'success',
        text: '3',
      },
      component: ReportShopee,
      route: PrivateRoute,
    }, */
    {
      path: '/webhook/:id',
      name: 'webhook',
      badge: {
        variant: 'success',
        text: '3',
      },
      component: Webhook,
      route: PrivateRoute,
    },
    {
      path: '/generate-label',
      name: 'generateLabel',
      badge: {
        variant: 'success',
        text: '3',
      },
      component: GenerateLabel,
      route: PrivateRoute,
    },
    {
      path: '/drops-point/prospect',
      name: 'prospect',
      exact: true,
      badge: {
        variant: 'success',
        text: '3',
      },
      component: Prospect,
      route: PrivateRoute,
    },
    {
      path: '/drops-point/prospect/details/:id',
      name: 'prospectDetails',
      badge: {
        variant: 'success',
        text: '3',
      },
      component: ProspectDetails,
      route: PrivateRoute,
    },
    {
      path: '/prospection/signatories',
      name: 'signatories',
      exact: true,
      badge: {
        variant: 'success',
        text: '3',
      },
      component: Signatories,
      route: PrivateRoute,
    },
    {
      path: '/prospection/signatories/details/:id',
      name: 'signatoriesDetails',
      exact: true,
      badge: {
        variant: 'success',
        text: '3',
      },
      component: SignatoriesEdit,
      route: PrivateRoute,
    },
    {
      path: '/prospection/signatories/create',
      name: 'signatoriesDetails',
      exact: true,
      badge: {
        variant: 'success',
        text: '3',
      },
      component: SignatoriesEdit,
      route: PrivateRoute,
    },
    {
      path: '/prospection/model/:id',
      name: 'listModels',
      badge: {
        variant: 'success',
        text: '3',
      },
      component: CreateModel,
      route: PrivateRoute,
    },
    {
      path: '/prospection/models/create',
      name: 'listModels',
      badge: {
        variant: 'success',
        text: '3',
      },
      component: CreateModel,
      route: PrivateRoute,
    },
    {
      path: '/prospection/models',
      name: 'listModels',
      badge: {
        variant: 'success',
        text: '3',
      },
      component: Models,
      route: PrivateRoute,
    },
    {
      path: '/prospection/additives',
      name: 'listAdditives',
      exact,
      badge: {
        variant: 'success',
        text: '3',
      },
      component: Additives,
      route: PrivateRoute,
    },
    {
      path: '/prospection/additives/:id',
      name: 'listModels',
      badge: {
        variant: 'success',
        text: '3',
      },
      component: CreateAdditives,
      route: PrivateRoute,
    },
    {
      path: '/prospection/createAdditive',
      exact,
      name: 'createAdditives',
      badge: {
        variant: 'success',
        text: '3',
      },
      component: CreateAdditives,
      route: PrivateRoute,
    },
    {
      path: '/sellerOrders',
      name: 'sellerOrders',
      exact: true,
      badge: {
        variant: 'success',
        text: '3',
      },
      component: SellerOrders,
      route: PrivateRoute,
    },
  ],
};

const pudoPreRegistrationRoute = [
  {
    path: '/pontodrops-pre-registration',
    name: 'Pudo Pre Registration',
    component: PreRegistration,
    route: Route,
  },
];

const otherPublicRoutes = [
  {
    path: '/error-404',
    name: 'Error - 404',
    component: ErrorPageNotFound,
    route: Route,
  },
  {
    path: '/error-500',
    name: 'Error - 500',
    component: ServerError,
    route: Route,
  },
];

const defaultLabelRoute = [
  {
    path: '/LabelPage/:tracking_number',
    name: 'LabelPage',
    component: LabelPage,
    route: PrivateRoute,
  },
  {
    path: '/generate-labeless/:trackingNumbers',
    name: 'generateLabeless',
    component: GenerateLabeless,
    route: PrivateRoute,
  },
];

// flatten the list of all nested routes
const flattenRoutes = (routes) => {
  let flatRoutes = [];

  routes = routes || [];
  routes.forEach((item) => {
    flatRoutes.push(item);

    if (typeof item.children !== 'undefined') {
      flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
    }
  });
  return flatRoutes;
};

// auth
const authRoutes = [
  {
    path: '/account/logout',
    name: 'Logout',
    component: Logout,
    route: Route,
  },
];

// All routes
const authProtectedRoutes = [dashboardRoutes];
const publicRoutes = [
  rootRoute,
  ...authRoutes,
  ...otherPublicRoutes,
  ...defaultLabelRoute,
  ...pudoPreRegistrationRoute,
];

const authProtectedFlattenRoutes = flattenRoutes([...authProtectedRoutes]);
const publicProtectedFlattenRoutes = flattenRoutes([...publicRoutes]);

export { authProtectedFlattenRoutes, publicProtectedFlattenRoutes };
